import React from 'react'
import classNames from 'classnames'
import { MOBILE_BOUNDARY_WIDTH } from '../../constants'

import './NavMenu.scss'


const hasClass = (element: EventTarget, cssClass: string) => {
  if (element instanceof HTMLElement) {
    return element.className.indexOf(cssClass) >= 0
  }

  return false
}

const isMenuElement = ({ target }: React.SyntheticEvent<HTMLElement>) =>
  hasClass(target, 'nav-menu nav-li') || hasClass(target, 'nav-menu__link')

interface Props {
  children: React.ReactNode,
  title: string
}

export default class NavMenu extends React.Component<Props> {

  public state = {
    menuOpen: false
  }

  private menuRef: React.RefObject<HTMLLIElement>
  private subMenuRef: React.RefObject<HTMLUListElement>
  private navMenuLinkRef: React.RefObject<HTMLAnchorElement>

  private menuTimeout: NodeJS.Timeout
  private focusFirstLinkTimeout: NodeJS.Timeout

  constructor(props: Props) {
    super(props)

    this.menuRef = React.createRef()
    this.subMenuRef = React.createRef()
    this.navMenuLinkRef = React.createRef()
  }

  public openMenu = (event?: React.KeyboardEvent<HTMLElement>) => {
    if (event && isMenuElement(event)) {
      event.preventDefault()
    }

    clearTimeout(this.menuTimeout)

    if (!this.state.menuOpen) {
      this.setState({ menuOpen: true }, () => {
        document.addEventListener('focusout', this.closeMenuOnlyOnFocusOut)
      })
    }
  }

  public closeMenuOnlyOnFocusOut = () => {
    if (this.state.menuOpen) {
      const delay = window.innerWidth > MOBILE_BOUNDARY_WIDTH ? 500 : 100

      this.menuTimeout = setTimeout(() => {
        if (this.menuRef.current && !this.menuRef.current.contains(document.activeElement)) {
          this.closeMenu()
        }
      }, delay)
    }
  }

  public closeMenu = () => {
    this.setState({ menuOpen: false }, () => {
      document.removeEventListener('focusout', this.closeMenuOnlyOnFocusOut)
    })
  }

  public render() {
    const menuOpen = this.state.menuOpen

    return (
      <li
        className="nav-menu nav-li"
        onMouseEnter={this.handleToggleMenu}
        onMouseLeave={this.handleToggleMenu}
        onClick={this.handleToggleMenu}
        ref={this.menuRef}
      >
        <a
          className={classNames({
            'nav-menu__link': true,
            'nav-menu__link--active': menuOpen
          })}
          aria-haspopup="true"
          aria-expanded={menuOpen}
          href="#"
          ref={this.navMenuLinkRef}
        >
          {this.props.title}
          <span className="nav-menu__link__arrow-span" />
        </a>
        <div
          className={classNames({
            'nav-menu__items': true,
            'nav-menu__items--active': menuOpen
          })}
        >
          <ul
            role="menu"
            aria-label={this.props.title + ' menu'}
            onKeyDown={this.onKeyPress}
            ref={this.subMenuRef}
          >
            {this.props.children}
          </ul>
        </div>
      </li>
    )
  }

  private focusFirstSubMenuLink = () => {
    clearTimeout(this.focusFirstLinkTimeout)

    this.focusFirstLinkTimeout = setTimeout(() => {
      if (this.subMenuRef.current !== null) {
        const firstLink = this.subMenuRef.current.querySelector('a[href]') as HTMLElement
        if (firstLink !== null) {
          firstLink.focus()
        }
      }
    }, 100)
  }

  private handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (event.type === 'click') {
      if (!this.state.menuOpen) {
        this.openMenu()
        this.focusFirstSubMenuLink()
      } else {
        this.closeMenu()
      }
    } else if (event.type === 'mouseenter') {
      if (window.innerWidth > MOBILE_BOUNDARY_WIDTH) {
        if (!this.state.menuOpen) {
          this.openMenu()
        }
      }
    } else if (event.type === 'mouseleave') {
      if (window.innerWidth > MOBILE_BOUNDARY_WIDTH) {
        if (this.state.menuOpen) {
          this.closeMenu()
        }
      }
    }
  }

  private onKeyPress = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Escape') {
      event.stopPropagation()
      this.closeMenu()
      if (this.navMenuLinkRef.current !== null) {
        this.navMenuLinkRef.current.focus()
      }
    }
  }
}
