import React from 'react'

import './NavLi.scss'

interface Props {
  children: React.ReactNode
}

const NavLi: React.SFC<Props> = ({ children }) => <li className="nav-li">{children}</li>

export default NavLi
