import ReactGA from 'react-ga'
import Cookiebot from './Cookiebot'
import { Analytics } from './analytics'

export enum EventCategory {
  VIDEO = 'Video',
  DOWNLOAD = 'Download',
  FORM = 'Form'
}

export enum DownloadPlatform {
  WINDOWS = 'Windows',
  MAC = 'Mac'
}

export default class GoogleAnalytics implements Analytics {
  private currentPage?: string
  private isReady = false

  constructor(private propertyId: string) {}

  public initialise() {
    const consent = Cookiebot.findConsent()

    if (!consent) {
      Cookiebot.onChange(this.initialise.bind(this), { once: true })

      return
    }

    if (!this.isReady && consent.statistics) {
      ReactGA.initialize(this.propertyId)
      this.isReady = true

      if (this.currentPage) {
        // If we had a page view during initialisation then log it, by this point we're
        // certain that we have consent.
        this.pageView(this.currentPage)
        this.currentPage = undefined
      }
    }
  }

  public pageView(page: string) {
    if (this.isReady) {
      ReactGA.set({ page })
      ReactGA.pageview(page)
    } else {
      this.currentPage = page
    }
  }

  public videoPlayed(title: string, percent?: number) {
    if (percent != undefined) {
      this.event(EventCategory.VIDEO, `${percent}% watched`, title)
    } else {
      this.event(EventCategory.VIDEO, 'Played', title)
    }
  }

  public download(platform: DownloadPlatform) {
    this.event(EventCategory.DOWNLOAD, `${platform} Version`)
  }

  public formAction(form: string, action: string) {
    this.event(EventCategory.FORM, action, form)
  }

  public formError(description: string) {
    if (this.isReady) {
      ReactGA.exception({ description, fatal: false })
    }
  }

  private event(category: EventCategory, action: string, label?: string) {
    if (this.isReady) {
      ReactGA.event({ category, action, label })
    }
  }
}
