import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

interface Props {
  label: string,
  to: string,
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void,
  className?: string
}

const RouteNavLink: React.SFC<Props> = ({ label, to, className, onClick }) => (
  <Link
    role="menuitem"
    to={to}
    className={classNames(className)}
    onClick={onClick}
  >
    {label}
  </Link>
)

export default RouteNavLink
