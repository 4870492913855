import React from 'react'
import MediaQuery from 'react-responsive'
import LinkList from '../LinkList/LinkList'
import SocialLinks from '../SocialLinks/SocialLinks'
import FlexBox, { Justification } from '../FlexBox/FlexBox'
import { Link } from 'gatsby'
import Paragraph, { ParagraphFontSize } from '../Paragraph/Paragraph'

import './Footer.scss'

const Footer: React.FunctionComponent = () => {
  const currentYear = new Date().getFullYear()
  const copyrightMessage = `Copyright © Sonocent Ltd. ${currentYear}`

  return (
    <footer className="footer" id="footer" aria-label="Site index">
      <FlexBox justify={Justification.AROUND} wrap={false} className="footer__menu">
        <FlexBox className="footer__company-section" justify={Justification.CENTER} vertical>
          <MediaQuery query="( min-width: 1150px )">
            <SocialLinks />
            <Paragraph className="footer__copyright-notice" fontSize={ParagraphFontSize.SMALL} centered>
              {copyrightMessage}
            </Paragraph>
          </MediaQuery>
        </FlexBox>
        <LinkList title="Products">
          <a href="https://glean.co" target="__blank">
            Glean
          </a>
          <Link to="/audio-notetaker">Audio Notetaker</Link>
          <Link to="/">Why audio notes?</Link>
          <a href="https://glean.co/pricing" target="__blank">
            Plans & Pricing (Glean)
          </a>
          <a href="https://app.glean.co/try" target="__blank">
            Try Glean for free
          </a>
          <Link to="/download">Download Audio Notetaker</Link>
          <a href="https://glean.co/institutions" target="__blank">
            Glean for Education
          </a>
          <a href="http://try.sonocent.com/dsa-hub/" target="__blank">
            For DSA
          </a>
        </LinkList>
        <LinkList title="Resources">
          <a href="https://support-hub.sonocent.com/" target="__blank">
            Support Hub
          </a>
          <a href="https://glean.co/blog/" target="__blank">
            Blog
          </a>
          <a href="https://legal.sonocent.com/" target="__blank">
            Privacy
          </a>
          <a href="https://legal.sonocent.com/eula/" target="__blank">
            EULA
          </a>
          <Link to="/cookies">Cookie Preferences</Link>
          <a href="https://legal.sonocent.com/data-security-policy/" target="__blank">
            Data Security Policy
          </a>
        </LinkList>
        <LinkList title="Company">
          <a href="https://glean.co/our-company/about-us/" target="__blank">
            About
          </a>
          <a href="https://glean.co/our-company/careers/" target="__blank">
            Careers
          </a>
          <a href="https://glean.co/contact/" target="__blank">
            Contact
          </a>
        </LinkList>
        <MediaQuery query="( max-width: 1149px )">
          <FlexBox justify={Justification.AROUND} vertical>
            <SocialLinks />
            <Paragraph centered>{copyrightMessage}</Paragraph>
          </FlexBox>
        </MediaQuery>
      </FlexBox>
    </footer>
  )
}

export default Footer
