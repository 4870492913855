import React from 'react'
import classNames from 'classnames'
import { MOBILE_BOUNDARY_WIDTH } from '../../constants'
import CompanyLogo from '../CompanyLogo/CompanyLogo'

import './NavBar.scss'

interface Props {
  children: (onClose: () => void) => React.ReactNode
}

interface State {
  mobileMenuOpen: boolean
  menuAccessible: boolean
  lastScrollY: number
  prevScrollDown: boolean
}

export const Key = Object.freeze({
  TAB: 'Tab',
  ESCAPE: 'Escape',
  END: 'End',
  HOME: 'Home'
})

export default class NavBar extends React.Component<Props, State> {
  public state = {
    mobileMenuOpen: false,
    menuAccessible: true,
    lastScrollY: 0,
    prevScrollDown: false
  }

  private menuRef: React.RefObject<HTMLElement>
  private openMenuButton: React.RefObject<HTMLButtonElement>
  private closeMenuButton: React.RefObject<HTMLButtonElement>
  private navBarRef: React.RefObject<HTMLDivElement>

  private timeout: NodeJS.Timeout
  private focusCloseButtonTimeout: NodeJS.Timeout
  private addBodyClassTimeOut: NodeJS.Timeout

  constructor(props: Props) {
    super(props)

    this.navBarRef = React.createRef()
    this.menuRef = React.createRef()
    this.openMenuButton = React.createRef()
    this.closeMenuButton = React.createRef()
  }

  public componentDidMount() {
    window.addEventListener('scroll', this.isStickyNav)
    if (window.innerWidth > MOBILE_BOUNDARY_WIDTH) {
      this.setState({ mobileMenuOpen: false, menuAccessible: true })
    } else {
      this.setState({ mobileMenuOpen: false, menuAccessible: false })
    }
  }

  public openMenu = () => {
    this.setState({ mobileMenuOpen: true, menuAccessible: true })

    window.addEventListener('resize', this.addDimensionsTestTimeout)

    this.focusCloseButtonTimeout = setTimeout(() => {
      this.focusReference(this.closeMenuButton)
    }, 100)

    this.addBodyClassTimeOut = setTimeout(() => {
      document.body.classList.add('menu-open')
    }, 500)
  }

  public closeMenu = () => {
    this.focusReference(this.openMenuButton)
    this.setState({ mobileMenuOpen: false, menuAccessible: false })
    document.body.classList.remove('menu-open')

    clearTimeout(this.timeout)
    clearTimeout(this.focusCloseButtonTimeout)
    clearTimeout(this.addBodyClassTimeOut)

    window.removeEventListener('resize', this.addDimensionsTestTimeout)
  }

  public onKeyPress = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (this.menuRef.current === null) {
      return
    }

    const focusableEls = this.menuRef.current.querySelectorAll('a[href], button')
    const first = focusableEls.item(0) as HTMLElement
    const last = focusableEls.item(focusableEls.length - 1) as HTMLElement

    switch (event.key) {
      case Key.TAB:
        if (event.shiftKey) {
          if (document.activeElement === first) {
            last.focus()
            event.preventDefault()
          }
        } else {
          if (document.activeElement === last) {
            first.focus()
            event.preventDefault()
          }
        }
        break
      case Key.ESCAPE:
        this.closeMenu()
        break
      case Key.HOME:
        first.focus()
        event.preventDefault()
        break
      case Key.END:
        last.focus()
        event.preventDefault()
        break
      default:
        return
    }
  }

  public render() {
    const { mobileMenuOpen, menuAccessible } = this.state
    const { children } = this.props

    return (
      <div ref={this.navBarRef} className="nav-bar">
        <div className="nav-bar__header">
          <CompanyLogo />
        </div>
        <div className="nav-bar__main-nav-container">
          <button
            className="nav-bar__main-nav-container__burger-menu-button"
            aria-haspopup="true"
            aria-expanded={mobileMenuOpen}
            aria-controls="main-menu"
            aria-label="open menu"
            ref={this.openMenuButton}
            onClick={this.openMenu}
          >
            Menu
          </button>
          <nav
            aria-label="main-menu"
            id="main-menu"
            aria-hidden={!menuAccessible}
            className={classNames('nav-bar__main-nav', {
              'nav-bar__main-nav--active': mobileMenuOpen
            })}
            ref={this.menuRef}
            onKeyDown={mobileMenuOpen ? this.onKeyPress : () => {}}
          >
            <button
              className={classNames('nav-bar__main-nav__burger-menu-close-button', {
                'nav-bar__main-nav__burger-menu-close-button--active': mobileMenuOpen
              })}
              aria-controls="main-menu"
              aria-label="close menu"
              ref={this.closeMenuButton}
              onClick={this.closeMenu}
            >
              Close
            </button>
            {children(this.closeMenu)}
          </nav>
        </div>
      </div>
    )
  }

  private addDimensionsTestTimeout = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.checkWindowDimensions, 250)
  }

  private checkWindowDimensions = () => {
    if (window.innerWidth > MOBILE_BOUNDARY_WIDTH) {
      this.setState({ mobileMenuOpen: false, menuAccessible: true })
      document.body.classList.remove('menu-open')
    }
  }

  private focusReference = (ref: React.RefObject<HTMLElement>): void => {
    if (ref.current !== null) {
      ref.current.focus()
    }
  }

  private isStickyNav = () => {
    const navElement = this.navBarRef.current

    if (navElement === null) {
      return
    }

    const navHeight = navElement.clientHeight
    const scrollY = window.pageYOffset

    if (scrollY <= navHeight) {
      navElement.classList.remove('nav-bar--hide')
      navElement.classList.remove('nav-bar--show-on-scroll-up')
      this.setState({ prevScrollDown: false })
    } else if (scrollY > this.state.lastScrollY || (scrollY === this.state.lastScrollY && this.state.prevScrollDown)) {
      navElement.classList.add('nav-bar--hide')
      navElement.classList.remove('nav-bar--show-on-scroll-up')
      this.setState({ prevScrollDown: true })
    } else {
      navElement.classList.add('nav-bar--show-on-scroll-up')
      navElement.classList.remove('nav-bar--hide')
      this.setState({ prevScrollDown: false })
    }

    this.setState({ lastScrollY: window.pageYOffset })
  }
}
