import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import './RouteButton.scss'

interface Props {
  to: string
  primary?: boolean
  nav?: boolean
  className?: any
  newTab?: boolean
  onClick?: any
  children: any
}

const RouteButton: React.SFC<Props> = ({ to, primary, nav, className, newTab, onClick, children }) => (
  <Link
    to={to}
    target={newTab ? '_blank' : '_self'}
    className={classNames({
      [className]: !!className,
      'route-button': true,
      'route-button--primary': primary,
      'route-button--nav': nav
    })}
    onClick={onClick}
  >
    {children}

    {nav ? <div className="route-button__download-image" /> : undefined}
  </Link>
)

RouteButton.defaultProps = {
  primary: false,
  newTab: false
}

export default RouteButton
