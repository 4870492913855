import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title: string
}

const Head: React.FunctionComponent<Props> = ({ title }) => (
  <Helmet>
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="copyright" content="Sonocent is a registered trademark of Sonocent Ltd." />
    <meta
      name="description"
      content="Struggle to take written notes? Improve your note taking skills using audio notes on Sonocent Audio Notetaker, the note taking app and software for students and professionals."
    />
    <meta
      name="keywords"
      content="Sonocent, Sonocent Audio Notetaker, note taking app, note taking software, note taking skills"
    />
    <meta name="robots" content="index, follow" />

    {/* OpenGraph meta to show a preview when website linked to from social media */}
    <meta name="og:title" content="Sonocent" />
    <meta
      name="og:description"
      content="Struggle to take written notes? Improve your note taking skills using audio notes on Sonocent Audio Notetaker, the note taking app and software for students and professionals."
    />
    <meta name="og:type" content="website" />
    <meta name="og:url" content="https://sonocent.com/" />
    <meta name="og:image" content="https://sonocent.com/audio-notetaker.png" />

    <link rel="shortcut icon" href="/favicon.ico" />
    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,700" rel="stylesheet" />

    <title>{title}</title>

    {/* Embedded scripts required by Marketing */}
    <script src="https://a9d6f2376c104cd9bd0f7a3f4a4ed13d.js.ubembed.com" async />
  </Helmet>
)

export default Head
