import GoogleAnalytics from './GoogleAnalytics'

const GOOGLE_ANALYTICS_PROPERTY = 'UA-38341593-5'

export interface Analytics {
  initialise(): void
  pageView(page: string): void
  download(message: string): void
  videoPlayed(title: string, percent?: number): void
  formAction(form: string, action: string): void
  formError(description: string): void
}

export default new GoogleAnalytics(GOOGLE_ANALYTICS_PROPERTY)