import React from 'react'
import Head from './Head'
import Navigation from './Navigation'
import Footer from '../components/Footer/Footer'
import './MainLayout.scss'
import Analytics from '../util/analytics'
import Tracker from '../components/Tracker/Tracker'

interface MainLayoutProps {
  title: string
}

export default class MainLayout extends React.Component<MainLayoutProps> {
  public componentDidMount() {
    Analytics.initialise()

    const page = window.location.pathname + window.location.search
    Analytics.pageView(page)
  }

  public render() {
    const { children, title } = this.props

    return (
      <div className="app">
        <Head title={title} />
        <ul className="app__skip-links">
          <li className="app__skip-links__link">
            <a href="#jumbotron-title">Skip to main content</a>
          </li>
          <li className="app__skip-links__link">
            <a href="#footer">Skip to site index</a>
          </li>
        </ul>

        <Navigation />

        <div className="app__content-container">{children}</div>

        <Footer />

        <Tracker src="/facebookPixel.js" async />
        <Tracker src="/zohoMarketingHubTag.js" async />
        <Tracker src="/twitterTag.js" async />
        <Tracker src="/linkedInInsightTag.js" async />
      </div>
    )
  }

  public hideBanner = () => {
    this.setState({ hasBanner: false })
  }
}
