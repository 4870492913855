import React from 'react'

export interface TrackerProps {
  src: string
  async?: boolean
  onInit?: () => void
  onInject?: () => void
}

export default class Tracker extends React.Component<TrackerProps> {
  public componentDidMount() {
    const { src, async = false, onInject, onInit } = this.props

    if (onInit) {
      onInit()
    }

    const script = document.createElement('script')
    script.src = src
    script.async = async

    // Setup so CookieBot can block the scripts
    script.type = 'text/plain'
    script.dataset.cookieconsent = 'marketing'

    document.body.appendChild(script)

    if (onInject) {
      onInject()
    }
  }

  public componentWillUnmount() {
    const script = this.findScript()

    if (script && script.parentElement) {
      script.parentElement.removeChild(script)
    }
  }

  public findScript = () => document.querySelector(`script[src="${this.props.src}"]`)

  public render() {
    return <></>
  }
}
