import React from 'react'
import classNames from 'classnames'

import './FlexBox.scss'

export enum Justification {
  START,
  END,
  AROUND,
  CENTER,
  BETWEEN,
  EVENLY,
  NORMAL
}

interface Props {
  children: any,
  vertical?: boolean,
  justify: Justification,
  className?: any
  wrap?: boolean
}

const FlexBox: React.SFC<Props> = ({ children, vertical, justify, className, wrap = true }) => (
  <div
    className={classNames({
      flexbox: true,
      [className]: !!className,
      'flexbox--vertical': vertical,
      'flexbox--justify-start': justify === Justification.START,
      'flexbox--justify-end': justify === Justification.END,
      'flexbox--justify-around': justify === Justification.AROUND,
      'flexbox--justify-center': justify === Justification.CENTER,
      'flexbox--justify-between': justify === Justification.BETWEEN,
      'flexbox--justify-evenly': justify === Justification.EVENLY,
      'flexbox--justify-normal': justify === Justification.NORMAL,
      'flexbox--wrap': wrap
    })}
  >
    {children}
  </div>
)

FlexBox.defaultProps = {
  vertical: false,
  className: ''
}

export default FlexBox
