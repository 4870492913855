import React from 'react'

import './SocialLink.scss'
import twitterIcon from './twitter.png'
import facebookIcon from './facebook.png'
import youTubeIcon from './youtube.png'

const iconsByService = Object.freeze({
  twitter: twitterIcon,
  facebook: facebookIcon,
  youtube: youTubeIcon
})

const namesByService = Object.freeze({
  twitter: 'Twitter',
  facebook: 'Facebook',
  youtube: 'YouTube'
})

interface Props {
  target: string
  service: string
}

const SocialLink: React.SFC<Props> = ({ target, service }) => (
  <a className="social-link" href={target} target="_blank" rel="noreferrer">
    <img
      src={iconsByService[service]}
      alt={`Find Glean at ${namesByService[service]}`}
      className="social-link__image"
    />
  </a>
)

export default SocialLink
