import React from 'react'

import './SocialLinks.scss'
import FlexBox, { Justification } from '../FlexBox/FlexBox'
import SocialLink from '../SocialLink/SocialLink'

const SocialLinks: React.SFC<{}> = () => (
  <FlexBox justify={Justification.CENTER} className="social-links">
    <SocialLink service="twitter" target="https://twitter.com/sonocent" />
    <SocialLink service="youtube" target="https://www.youtube.com/c/Sonocent" />
    <SocialLink service="facebook" target="https://www.facebook.com/AudioNotetaker/" />
  </FlexBox>
)

export default SocialLinks
