import React from 'react'

import NavBar from '../components/NavBar/NavBar'
import RouteNavLink from '../components/RouteNavLink/RouteNavLink'
import NavMenu from '../components/NavMenu/NavMenu'
import NavMenuItem from '../components/NavMenuItem/NavMenuItem'
import RouteButton from '../components/RouteButton/RouteButton'
import NavLi from '../components/NavLi/NavLi'
import './Navigation.scss'

const Navigation: React.FunctionComponent = () => (
  <NavBar>
    {(closeMenu: () => void) => (
      <ul className="navigation">
        <NavLi>
          <RouteNavLink label="About" to="/audio-notetaker" className="nav-link" onClick={closeMenu} />
        </NavLi>
        <NavLi>
          <a
            role="menuitem"
            className="nav-link"
            href="https://support-hub.sonocent.com/"
            target="_blank"
            onClick={closeMenu}
          >
            Support
          </a>
        </NavLi>
        <NavMenu title="Why upgrade?">
          <NavMenuItem
            label="Institutions"
            to="https://glean.co/why-upgrade-to-glean-for-education/"
            onClick={closeMenu}
          />
          <NavMenuItem label="Personal Users" to="https://glean.co/why-upgrade-to-glean/" onClick={closeMenu} />
        </NavMenu>
        <NavLi>
          <RouteNavLink label="Company" to="/about" className="nav-link" onClick={closeMenu} />
        </NavLi>
        <NavLi>
          <RouteButton to="/download" nav onClick={closeMenu}>
            Download
          </RouteButton>
        </NavLi>
      </ul>
    )}
  </NavBar>
)

export default Navigation
