import React, { ReactNode } from 'react'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import './LinkList.scss'

interface Props {
  title: string
  children: any
}

interface State {
  expanded: boolean
}

const KEY_ENTER = 'Enter'
const KEY_SPACE = ' '
const KEY_SPACE_LEGACY = 'Spacebar'

const isSpaceOrEnter = (key: string) => key === KEY_ENTER || key === KEY_SPACE || key === KEY_SPACE_LEGACY

export default class LinkList extends React.Component<Props, State> {
  public state = {
    expanded: false
  }

  public render() {
    const { children, title } = this.props
    const { expanded } = this.state

    return (
      <div className="link-list">
        <MediaQuery query="( min-width: 1100px )">
          <p className="link-list__title">{title}</p>
          <ul className="link-list__list" aria-label={title}>
            {children.map((child: ReactNode, i: number) => (
              <li key={title + `-${i}`}>{child}</li>
            ))}
          </ul>
        </MediaQuery>
        <MediaQuery query="( max-width: 1099px )">
          <p
            role="button"
            className="link-list__title"
            aria-controls="link-list__list"
            aria-expanded={expanded}
            onClick={this.handleClick}
            onKeyPress={this.handleKeyPress}
            tabIndex={0}
          >
            {title}
          </p>
          <ul
            className={classNames('link-list__list', {
              'link-list__list--expanded': expanded
            })}
            aria-hidden={!expanded}
            aria-label={title}
          >
            {children.map((child: ReactNode, i: number) => (
              <li key={title + `-${i}`}>{child}</li>
            ))}
          </ul>
        </MediaQuery>
      </div>
    )
  }

  private handleClick = () => this.toggleCollapsed()

  private handleKeyPress = (event: React.KeyboardEvent<HTMLParagraphElement>) => {
    if (isSpaceOrEnter(event.key)) {
      this.toggleCollapsed()
    }
  }

  private toggleCollapsed = () => this.setState({ expanded: !this.state.expanded })
}
