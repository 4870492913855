class Cookiebot {
  public onChange(callback: () => void, options?: EventListenerOptions) {
    this.addEventListener('CookiebotOnLoad', callback, options)
  }

  public findConsent(): CookiebotConsent | undefined {
    if (window.Cookiebot && window.Cookiebot.hasResponse) {
      return window.Cookiebot.consent
    }

    return undefined
  }

  private addEventListener(event: CookiebotEvent, callback: () => void, options?: EventListenerOptions) {
    window.addEventListener(event, callback, options)
  }
}

export default new Cookiebot()
