import React from 'react'

import './NavMenuItem.scss'

interface Props {
  label: string
  to: string
  newTab?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const NavMenuItem: React.SFC<Props> = ({ label, to, newTab, onClick }) => (
  <li role="presentation" className="nav-menu-item-li">
    <a role="menuitem" href={to} target={newTab ? '_blank' : '_self'} onClick={onClick}>
      <span className="nav-menu-item-li__title">{label}</span>
    </a>
  </li>
)

NavMenuItem.defaultProps = {
  newTab: false
}

export default NavMenuItem
