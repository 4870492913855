import React from 'react'
import classNames from 'classnames'

import FlexBox, { Justification } from '../FlexBox/FlexBox'
import './CompanyLogo.scss'
import companyLogo from './company-logo.png'

interface Props {
  secondary?: boolean
}

const CompanyLogo: React.SFC<Props> = ({ secondary }) => (
  <a href="/" className="company-logo">
    <FlexBox justify={Justification.CENTER} className="company-logo__container">
      <FlexBox justify={Justification.START} className="company-logo__container__image-container">
        <img className="company-logo__container__image-container__image" src={companyLogo} alt="Sonocent Logo" />
      </FlexBox>
      <FlexBox justify={Justification.START} className="company-logo__container__name-container">
        <span
          className={classNames({
            'company-logo__container__name-container__name': true,
            'company-logo__container__name-container__name--secondary': secondary
          })}
        />
      </FlexBox>
    </FlexBox>
  </a>
)

export default CompanyLogo
