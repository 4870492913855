import React from 'react'
import classNames from 'classnames'

import './Paragraph.scss'

export enum ParagraphFontSize {
  XSMALL,
  SMALL,
  MEDIUM,
  LARGE,
  CUSTOM
}

export enum ParagraphFontWeight {
  LIGHT,
  REGULAR,
  SEMIBOLD,
  BOLD,
  CUSTOM
}

interface Props {
  fontSize?: ParagraphFontSize
  fontWeight?: ParagraphFontWeight
  className?: any
  noMargin?: boolean
  noFontStyle?: boolean
  children: any
  centered?: boolean
}

const Paragraph: React.SFC<Props> = ({ fontSize, fontWeight, noMargin, noFontStyle, className, children, centered }) => (
  <p
    className={classNames({
      [className]: !!className,
      'paragraph--xsmall': fontSize === ParagraphFontSize.XSMALL && !noFontStyle,
      'paragraph--small': fontSize === ParagraphFontSize.SMALL && !noFontStyle,
      'paragraph--medium': fontSize === ParagraphFontSize.MEDIUM && !noFontStyle,
      'paragraph--large': fontSize === ParagraphFontSize.LARGE && !noFontStyle,
      'paragraph--light': fontWeight === ParagraphFontWeight.LIGHT && !noFontStyle,
      'paragraph--regular': fontWeight === ParagraphFontWeight.REGULAR && !noFontStyle,
      'paragraph--semi-bold': fontWeight === ParagraphFontWeight.SEMIBOLD && !noFontStyle,
      'paragraph--bold': fontWeight === ParagraphFontWeight.BOLD && !noFontStyle,
      'paragraph--no-margin': noMargin,
      'paragraph--centered': centered
    })}
  >
    {children}
  </p>
)

Paragraph.defaultProps = {
  fontSize: ParagraphFontSize.MEDIUM,
  fontWeight: ParagraphFontWeight.REGULAR,
  noFontStyle: false,
  noMargin: false
}

export default Paragraph
